import React, { useContext } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import CloseIcon from '@material-ui/icons/Close';
import { fieldArrayPropTypes } from 'redux-form';

import noop from 'lodash/noop';
import AsyncAutocomplete from '../../AsyncAutocomplete/AsyncAutocomplete';
import AsyncAutocompleteArray from '../../AsyncAutocompleteArray/AsyncAutocompleteArray';
import Autocomplete from '../../Autocomplete/Autocomplete';
import SkholeIcon01 from '../Icons/SkholeIcon01';
import SkholeIcon17 from '../Icons/SkholeIcon17';
import SkholeIcon42 from '../Icons/SkholeIcon42';
import ContextProvider from '../../../context';

import s from './Forms.scss';

const SelectField = ({
  className,
  children,
  isLabelDisplayed,
  classes,
  SelectProps,
  ...rest
}) => (
  <TextField
    className={classNames(className)}
    InputLabelProps={{
      classes: {
        formControl: classNames(isLabelDisplayed ? s.labelOn : s.labelOff),
      },
    }}
    InputProps={{
      classes: {
        root: classNames(s.textField, classes.textField),
      },
    }}
    classes={{
      root: classNames(s.formRoot, classes.formRoot),
    }}
    select
    margin="none"
    SelectProps={{
      // eslint-disable-next-line react/no-unstable-nested-components
      IconComponent: () => (
        <SkholeIcon42
          className={classNames(s.selectIcon, classes.selectIcon)}
        />
      ),
      classes: {
        selectMenu: s.selectMenu,
        select: classes.select,
        root: classNames(classes.selectRoot),
      },
      ...SelectProps,
    }}
    {...rest}
  >
    {children}
  </TextField>
);

SelectField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isLabelDisplayed: PropTypes.bool,
  classes: PropTypes.shape({
    selectRoot: PropTypes.string,
    select: PropTypes.string,
    selectIcon: PropTypes.string,
    textField: PropTypes.string,
    formRoot: PropTypes.string,
  }),
  SelectProps: PropTypes.shape({
    // classes: PropTypes.shape({
    //   selectIcon: PropTypes.string,
    // }),
  }),
};

SelectField.defaultProps = {
  isLabelDisplayed: false,
  className: '',
  children: null,
  classes: {},
  SelectProps: {},
};

const SearchField = ({
  id,
  className,
  value,
  onSearchChange,
  clearSearch,
  ...rest
}) => (
  <TextField
    className={className}
    onChange={onSearchChange}
    value={value}
    {...rest}
    InputLabelProps={{
      classes: {
        formControl: s.labelOff,
      },
    }}
    inputProps={{
      id,
    }}
    InputProps={{
      classes: {
        root: s.searchInputRoot,
        input: s.textFieldInput,
      },
      endAdornment:
        clearSearch && value ? (
          <IconButton className={s.icon} onClick={clearSearch}>
            <CloseIcon />
          </IconButton>
        ) : (
          <IconButton>
            <SkholeIcon01 className={s.searchIcon} />
          </IconButton>
        ),
    }}
    classes={{
      root: s.searchRoot,
    }}
  />
);

SearchField.propTypes = {
  id: PropTypes.string,
  onSearchChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  clearSearch: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

SearchField.defaultProps = {
  id: '',
  value: '',
  className: '',
  clearSearch: false,
  onSearchChange: noop, // replaced to optional because in some places just onChange passed instead of onSearchChange
};

const StyledAsyncAutocomplete = ({
  className,
  styles,
  classes,
  inputPropsClasses,
  meta: { error, touched, submitFailed },
  meta,
  ...rest
}) => {
  const context = useContext(ContextProvider);
  return (
    <AsyncAutocomplete
      margin="none"
      meta={meta}
      InputProps={{
        classes: {
          ...inputPropsClasses,
          root: classNames(s.textField, inputPropsClasses.root, {
            [s.error]: !!((touched || submitFailed) && error),
          }),
        },
      }}
      InputLabelProps={{
        classes: {
          formControl: s.labelOff,
        },
      }}
      className={s.asyncMenu}
      classes={{
        root: classNames(s.formRoot, className),
        ...classes,
      }}
      styles={styles || context.primaryAutocompleteStyles}
      {...rest}
    />
  );
};

StyledAsyncAutocomplete.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  classes: PropTypes.shape({}),
  inputPropsClasses: PropTypes.shape({
    root: PropTypes.string,
  }),
  meta: PropTypes.oneOfType([PropTypes.shape({}), fieldArrayPropTypes.meta]),
};
StyledAsyncAutocomplete.defaultProps = {
  meta: {},
};

const StyledAsyncAutocompleteArray = ({
  className,
  styles,
  classes,
  inputPropsClasses,
  meta,
  meta: { error, touched, submitFailed },
  ...rest
}) => {
  const context = useContext(ContextProvider);
  return (
    <AsyncAutocompleteArray
      margin="none"
      InputProps={{
        classes: {
          ...inputPropsClasses,
          root: classNames(s.textField, inputPropsClasses.root, {
            [s.error]: !!((touched || submitFailed) && error),
          }),
        },
      }}
      meta={meta}
      InputLabelProps={{
        classes: {
          formControl: s.labelOff,
        },
      }}
      className={s.asyncMenu}
      classes={{
        root: classNames(s.formRoot, className),
        ...classes,
      }}
      styles={styles || context.primaryAutocompleteStyles}
      {...rest}
    />
  );
};

StyledAsyncAutocomplete.defaultProps = {
  className: '',
  styles: false,
  classes: {},
  inputPropsClasses: {},
};

StyledAsyncAutocompleteArray.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  classes: PropTypes.shape({ root: PropTypes.string, chip: PropTypes.string }),
  inputPropsClasses: PropTypes.shape({
    root: PropTypes.string,
  }),
  // eslint-disable-next-line react/require-default-props
  meta: fieldArrayPropTypes.meta,
};

StyledAsyncAutocompleteArray.defaultProps = {
  className: '',
  styles: false,
  classes: {},
  inputPropsClasses: {},
};

const StyledAutocomplete = ({ className, styles, ...rest }) => {
  const context = useContext(ContextProvider);
  return (
    <Autocomplete
      margin="none"
      InputProps={{
        classes: {
          root: s.textField,
        },
      }}
      InputLabelProps={{
        classes: {
          formControl: s.labelOff,
        },
      }}
      classes={{ root: classNames(s.formRoot, className) }}
      styles={styles || context.primaryAutocompleteStyles}
      {...rest}
    />
  );
};

StyledAutocomplete.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.oneOf([PropTypes.shape({}), PropTypes.bool]),
};

StyledAutocomplete.defaultProps = {
  className: '',
  styles: false,
};

const StyledAddNewButton = ({ className, title, onClick, ...rest }) => (
  <div className={classNames(s.addBtn, className)}>
    <Tooltip title={title} enterDelay={300}>
      <Button
        color="primary"
        className={s.addIcon}
        // classes={{
        //   root: s.btnRoot,
        // }}
        onClick={onClick}
        {...rest}
      >
        <SkholeIcon17 />
      </Button>
    </Tooltip>
  </div>
);

StyledAddNewButton.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

StyledAddNewButton.defaultProps = {
  className: '',
  title: '',
};

const ActiveArchivedGroup = ({
  value,
  onArchivedClick,
  onActiveClick,
  labels: { archived: archivedLabel, active: activeLabel },
  classes,
}) => (
  <FormControl
    component="fieldset"
    classes={{
      root: classNames(s.fieldSet, classes.fieldSet),
    }}
  >
    <RadioGroup
      aria-label="archived"
      name="archived"
      className={s.group}
      value={value}
      row
    >
      <FormControlLabel
        value="false"
        classes={{
          root: s.checkRoot,
          label: s.checkLabel,
        }}
        control={<Radio color="primary" classes={{ root: s.radioRoot }} />}
        onClick={onActiveClick}
        label={activeLabel}
      />
      <FormControlLabel
        value="true"
        classes={{
          root: s.checkRoot,
          label: s.checkLabel,
        }}
        control={<Radio color="primary" classes={{ root: s.radioRoot }} />}
        onClick={onArchivedClick}
        label={archivedLabel}
      />
    </RadioGroup>
  </FormControl>
);

ActiveArchivedGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  onArchivedClick: PropTypes.func.isRequired,
  onActiveClick: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    archived: PropTypes.string.isRequired,
    active: PropTypes.string.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    fieldSet: PropTypes.string.isRequired,
  }),
};

ActiveArchivedGroup.defaultProps = {
  classes: {},
};

export const ToolbarActiveArchivedGroup = withStyles(s)(ActiveArchivedGroup);

export const ToolbarSelectField = withStyles(s)(SelectField);

export const ToolbarSearchField = withStyles(s)(SearchField);

export const ToolbarAsyncAutocompleteArray = withStyles(s)(
  StyledAsyncAutocompleteArray,
);

export const ToolbarAsyncAutocomplete = withStyles(s)(StyledAsyncAutocomplete);

export const ToolbarAutocomplete = withStyles(s)(StyledAutocomplete);

export const ToolbarAddNewBtn = withStyles(s)(StyledAddNewButton);
