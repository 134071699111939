/* eslint-disable react/prop-types */

import debounce from 'debounce-promise';
import React from 'react';
import _ from 'lodash';
// import PropTypes from 'prop-types';
// import { fieldInputPropTypes } from 'redux-form';
import {
  withStyles as withMuiStyles,
  // StyledComponentProps,
} from '@material-ui/core/styles';

import AsyncSelect /* , { Props } */ from 'react-select/async';
// import { ValueType } from 'react-select/src/types';
// import { SelectComponentsConfig } from 'react-select/src/components/index';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import {
  styles,
  Option,
  DropdownIndicator,
  Control,
  ValueContainer,
  SingleValue,
  MultiValue,
  NoOptionsMessage,
  Placeholder,
  Menu,
} from '../Autocomplete/Autocomplete';

export const messages = defineMessages({
  defaultTotalInputPlaceholder: {
    id: 'AsyncAutocomplete.defaultTotalInputPlaceholder',
    defaultMessage: 'Start typing to find {name}... ({total} {name}`s) ',
    description: 'Start typing to find... ({total} items) ',
  },
  noResultFound: {
    id: 'AsyncAutocomplete.noResultFound',
    defaultMessage: 'No results found',
    description: 'No results found displayed in async autocomplete form',
  },
  selectLabel: {
    id: 'AsyncAutocomplete.selectLabel',
    defaultMessage: 'Select ...',
    description:
      'Select ... displayed in async autocomplete form to select item',
  },
  itemsLabel: {
    id: 'AsyncAutocomplete.itemsLabel',
    defaultMessage: ' ({items} items)',
    description:
      '"Items" label displayed in async autocomplete form right after amount of total items',
  },
  startTypingNameHere: {
    id: 'AsyncAutocomplete.startTypingNameHere',
    defaultMessage: 'Start typing name here...',
    description:
      '"Start typing name here" label displayed in async autocomplete as default placeholder',
  },
  loading: {
    id: 'AsyncAutocomplete.loading',
    defaultMessage: 'Loading...',
  },
});

// export {
//   primaryAutocompleteStyles, // tableFilterAutocompleteStyles,
// } from '../Autocomplete/Autocomplete';

const noOptionsMessage = () => <FormattedMessage {...messages.noResultFound} />;

@withMuiStyles(styles)
@injectIntl
export default class AsyncAutocompleteArray extends React.Component {
  static defaultProps = {
    debounceInterval: 300,
  };

  // static propTypes = {
  //   changeSearchField: PropTypes.func,
  //   margin: PropTypes.oneOf('none', 'normal'),
  //   placeholder: PropTypes.oneOfType(PropTypes.node, PropTypes.string),
  //   inputPlaceholder: PropTypes.oneOfType(PropTypes.node, PropTypes.string),
  //   styles: PropTypes.shape({}),
  //   components: PropTypes.shape({}),
  //   value: PropTypes.shape({}),
  //   // components: SelectComponentsConfig,
  //   // ...Props,
  //   ...StyledComponentProps,
  //   ...fieldInputPropTypes,
  // };

  // static defaultProps = {
  //   changeSearchField: () => null,
  //   value: null,
  //   margin: 'none',
  //   placeholder: <FormattedMessage {...messages.selectLabel} />,
  //   inputPlaceholder: '',
  //   styles: {},
  //   components: {},
  // };

  constructor(props) {
    super(props);

    const { loadOptions = () => null, debounceInterval } = props;

    if (!debounceInterval) {
      this.loadOptionsDebounced = loadOptions;
      return;
    }

    this.loadOptionsDebounced = debounce(loadOptions, debounceInterval);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.fields.length && this.props.fields.length) {
      nextProps.loadOptions('');
    }
  }

  onInputChange = value => {
    if (!value && this.props.searchValue) {
      this.loadOptionsDebounced('');
    }

    if (this.props.changeSearchField) {
      this.props.changeSearchField(value);
    }
    return value;
  };

  render() {
    const {
      id,
      classes = {},
      fields,
      beforeChange,
      margin = 'none',
      placeholder = <FormattedMessage {...messages.selectLabel} />,
      inputProps = {},
      InputProps = {},
      inputPlaceholder = '',
      InputLabelProps = {},
      meta: { error, touched, submitFailed },
      helperText = '',

      searchValue = '',
      loadOptions = () => null, // eslint-disable-line no-unused-vars
      debounceInterval, // eslint-disable-line no-unused-vars
      components = {},
      loadOptionsTotal = 0,
      intl: { formatMessage },
      noneDefaultPlaceholder = false,
      changeKey,
      ...other
    } = this.props;

    let placeholderVal = inputPlaceholder;
    if (!noneDefaultPlaceholder) {
      const defaultPlaceholder =
        inputPlaceholder || formatMessage(messages.startTypingNameHere);

      placeholderVal = `${defaultPlaceholder} ${formatMessage(
        messages.itemsLabel,
        {
          items: loadOptionsTotal,
        },
      )}`;
    }

    // It's huck in order to fix https://app.asana.com/0/1205168629240507/1202546495608266/f
    // (validation is not triggered after update of key, but without updating of key, loadOptions is not triggered and options are not updated after removing of some value)
    const keyProp = changeKey ? { key: changeKey } : {};

    return (
      <div className={classes.root}>
        <AsyncSelect
          loadOptions={this.loadOptionsDebounced}
          classes={classes}
          placeholder={placeholderVal}
          inputValue={searchValue}
          onInputChange={this.onInputChange}
          noOptionsMessage={noOptionsMessage}
          // ref={asyncRef}
          value={fields.getAll()}
          onChange={(value, event) => {
            switch (event.action) {
              case 'select-option':
                fields.push(event.option);
                break;
              case 'remove-value':
                fields.remove(_.indexOf(fields.getAll(), event.removedValue));
                break;
              case 'pop-value':
                fields.pop();
                break;
              case 'clear':
                fields.removeAll();
                break;
              default:
                break;
            }
          }}
          textFieldProps={{
            label: placeholder,
            helperText: ((touched || submitFailed) && error) || helperText,
            error: !!((touched || submitFailed) && error),
            InputLabelProps: {
              shrink: true,
              ...InputLabelProps,
            },
            margin,
          }}
          InputProps={{
            id,
            ...InputProps,
          }}
          inputProps={inputProps}
          maxMenuHeight={250}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
            Control,
            NoOptionsMessage,
            Placeholder,
            SingleValue,
            MultiValue,
            ValueContainer,
            Menu,
            ...components,
          }}
          styles={{
            input: base => ({
              ...base,
              color: '#fff',
            }),
            menu: base => ({
              ...base,
              borderRadius: 0,
            }),
            placeholder: base => ({
              ...base,
              color: '#fff',
            }),
            singleValue: base => ({
              ...base,
              color: '#fff',
            }),
            valueContainer: base => ({
              ...base,
              color: '#fff',
            }),
            ...this.props.styles,
          }}
          loadingMessage={() => formatMessage(messages.loading)}
          margin
          {...keyProp}
          {...other}
        />
      </div>
    );
  }
}
