import React from 'react';
import { Icon } from './index';

const SkholeIcon17 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M31.3,17.2h-8c-0.4,0-0.7-0.3-0.7-0.7v-8c0-0.9-0.7-1.7-1.7-1.7h-2.2c-0.9,0-1.7,0.7-1.7,1.7v8
	c0,0.4-0.3,0.7-0.7,0.7h-8c-0.9,0-1.7,0.7-1.7,1.7v2.2c0,0.9,0.7,1.7,1.7,1.7h8c0.4,0,0.7,0.3,0.7,0.7v8c0,0.9,0.7,1.7,1.7,1.7H21
	c0.9,0,1.7-0.7,1.7-1.7v-8c0-0.4,0.3-0.7,0.7-0.7h8c0.9,0,1.7-0.7,1.7-1.7v-2.2C33,18,32.3,17.2,31.3,17.2z"
    />
  </svg>,
);

export default SkholeIcon17;
